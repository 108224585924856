@import "_generated-font-mixins.scss";
.footer__list {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  &:nth-child(1) {
    flex: 0 1 180px;
  }

  &:nth-child(2) {
    flex: 0 1 250px;
  }

  &:nth-child(3) {
    flex: 0 1 260px;
  }

  &:last-child {
    flex: 0 1 100px;
  }

  &__title {
    margin-bottom: 13px;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
  }

  &__item {
    margin-bottom: 3px;
    font-size: 12px;
    line-height: 17px;
    color: #c2c2c2;

    &:hover {
      & > span {
        text-decoration: none !important;
      }

      & a,
      span {
        text-decoration: underline;
      }
    }
  }

  &__icon {
    & > img {
      margin-top: 10px;
      margin-right: 8px;
    }
  }

  &__icons {
    display: flex;
    flex-flow: row wrap;
    margin-top: -6px;

    & > a {
      & > span {
        margin-right: 10px;

        &::before {
          font-size: 22px;
          color: #c2c2c2;
        }

        & > img {
          margin-top: 10px;
          margin-right: 8px;
        }
      }

      &:hover {
        & > span::before,
        & > img {
          opacity: 0.85;
        }
      }
    }
  }

  &__facebook::before {
    @include icon-facebook;
  }

  &__tiktok::before {
    @include icon-tiktok;
  }

  &__instagram::before {
    @include icon-instagram;
  }

  &__visa::before {
    @include icon-visa;

    font-size: 52px !important;
    line-height: 40px;
  }
}
