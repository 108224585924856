@import "_generated-font-mixins.scss";
.footer {
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 20px;

  color: #c2c2c2;

  background: #454c59;

  &__topSection {
    display: flex;
    flex-direction: row;

    margin-bottom: 30px;
    padding: 25px 0 8px;

    border-bottom: dashed 1px #7d828b;
  }

  &__bottomSection {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > a {
      display: flex;
      flex: 0 0 calc(100% / 7);
      flex-direction: column;
      align-items: flex-start;

      opacity: 0.7;
      filter: brightness(0) invert(1);

      &.simpals {
        opacity: 1;
      }

      &:nth-child(4) {
        position: relative;
        top: -10px;
        align-items: center;
      }

      &:nth-child(5) {
        align-items: flex-end;
      }

      &:nth-child(6) {
        align-items: flex-end;
      }

      &:nth-child(7) {
        align-items: flex-end;
      }

      &:hover {
        opacity: 1;
      }

      & > img {
        height: inherit;
      }
    }
  }

  &__copy {
    position: absolute;
    right: 0;
    bottom: -5px;
    left: 0;

    font-size: 11px;
    text-align: center;
  }
}
